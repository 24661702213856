<template>
  <div v-bind:style="styles" class="spinner spinner--socker">
    <div v-bind:style="innerStyles" class="spinner-inner">
      <div class="cube panelLoad">
        <div class='cube-face cube-face-front'>B</div>
        <div class='cube-face cube-face-back'>N</div>
        <div class='cube-face cube-face-right'>D</div>
        <div class='cube-face cube-face-bottom'>S</div>
        <div class='cube-face cube-face-top'>I</div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * esse componente é uma copia do lettercube usado na lib de loading que utilizamos no projeto
 * foi modificado para receber as letras da binds
 */
export default {
  props: {
    size: {
      default: '170px'
    }
  },
  computed: {
    innerStyles () {
      const size = parseInt(this.size) / 2
      return {
        transform: 'scale(' + (size / 75) + ')'
      }
    },
    styles () {
      return {
        width: this.size,
        height: this.size
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .spinner{
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    * {
      box-sizing: border-box;
    }
  }
  .cube {
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    transform-style: preserve-3d;
    transition: transform 0.5s 0.1s;
    perspective: 9999px;
    color: #333;
    opacity: 1;
    position: relative;
    &.panelLoad {
      z-index: 11;
      top: 50%;
      animation: letter-cube-panel 5.2s infinite forwards;

      .cube-face {
        font-family: "Open Sans",sans-serif;
        font-size: 50px;
        color: #9871dd;
        box-shadow: inset 0 0 0 1px #fcfbfb, 0 0 1px 1px #ededed;
      }
    }

    .cube-face {
      width: inherit;
      height: inherit;
      position: absolute;
      background: white;
      box-shadow: inset 0 0 0 1px fcfbfb, 0 0 1px 1px #ededed;
      opacity: 1;
    }

    .cube-face-front {
      transform: translate3d(0, 0, 40px);
      font-size: 57px;
    }

    .cube-face-back {
      transform: rotateY(180deg) translate3d(0, 0, 40px);
    }

    .cube-face-right {
      transform: rotateY(90deg) translate3d(0, 0, 40px);
    }

    .cube-face-top {
      transform: rotateX(90deg) translate3d(0, 0, 40px);
    }

    .cube-face-bottom {
      transform: rotateX(-90deg) translate3d(0, 0, 40px);
    }
  }

  @keyframes letter-cube-panel {
    0% {
      transform: rotateY(0deg) rotateZ(0deg);
    }
    40% {
      transform: rotateX(-180deg) rotateY(0deg);
    }
    60% {
      transform:  rotateY(-90deg) rotateZ(0deg);
    }
    80% {
      transform:  rotateX(90deg) rotateZ(0deg);
    }
  }
</style>
